<template>
  <div>
    <div class="wrap-body">
      <el-row :gutter="20">
        <el-col
          :lg="{span:'4-8'}"
          :sm="12"
          :xs="24"
          v-for="(item, index) in stateList"
          :key="index"
        >
          <div class="grid-content bg-purple" @click="getstateItem(item)">
            <div class="item-left">
              <i :class="item.icon"></i>
            </div>
            <div class="item-right">
              <div>
                <span style="padding-right: 10px">{{ item.label }}</span>
                <div class="wrap-num">{{ item.num > 99?'99+':item.num }}</div>
              </div>
            </div>
          </div></el-col
        >
        <el-col :span="24">
          <div class="wrap-bottom">
            <div class="table-header">
              <div class="theader-left">
                <span>{{ itemTitle ? itemTitle : "在办业务" }}</span>
              </div>

              <div class="theader-right">
                <el-select
                  v-show="itemTitle == '办结业务'"
                  filterable
                  style="width: 100px;margin-right: 0;"
                  v-model="selectEnd"
                  placeholder="请选择办结状态"
                  @change="whereconditions()"
                >
                  <el-option label="已结案" value="已结案" />
                  <el-option label="全部" value="" />
                </el-select>
                <el-select
                  filterable
                  v-model="selectOption"
                  placeholder="请选择业务类型"
                  @change="getywItem"
                >
                  <el-option
                    v-for="item in ywlxList"
                    :key="item.Id"
                    :label="item.label"
                    :value="item.Id"
                  >
                  </el-option>
                </el-select>
                <div class="wrap-iv">
                  <el-input
                    v-model.trim="inputValue"
                    placeholder="请输入项目名称,项目代码"
                  />
                </div>

                <el-button @click="handleQuery" size="medium">查询</el-button>
                <el-button
                  @click="handleReset"
                  style="margin-left: 0px"
                  size="medium"
                  >重置</el-button
                >
              </div>
            </div>
            <PublicTable ref="publicTable" :tableHeaders2="tableHeaders2" @changePage="handlePageChange">
              <template #td_ID="scope">
                    <el-button
                      size="mini"
                      type="text"
                      v-if="para.queryId == 'lahz_cx_db'"
                      @click="opentask(scope.$index, scope.row)"
                      >办理</el-button
                    >
                    <el-button
                      size="mini"
                      type="text"
                      v-else
                      @click="opentask(scope.$index, scope.row)"
                      >打开</el-button
                    >
                    <el-button
                      size="mini"
                      type="text"
                      v-if="para.queryId == 'lahz_cx_sc'"
                      @click="setYwsc(scope.$index, scope.row)"
                      >取消收藏</el-button
                    >
                    <el-button
                      size="mini"
                      type="text"
                      v-else-if="itemTitle != '办结业务'"
                      @click="setYwsc(scope.$index, scope.row)"
                      >{{
                        scope.row.favoritesid != null ? "取消收藏" : "收藏"
                      }}</el-button
                    >
                    <div
                      class="wp-num"
                      v-if="isOvertime(scope.row.JJRQ, scope.row.WorkTime, scope.row.LimitTime,scope.row.taskendtime)"
                    >
                      <span>已超期</span>
                    </div>
              </template>
            </PublicTable>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import PublicTable from '../../components/table/PublicTable.vue'
import mgr from '@/services/security.js'
export default {
  data() {
    return {
      input: '',
      stateList: [
        { Id: '1', label: '在办业务', icon: 'el-icon-edit-outline', num: 0 },
        { Id: '0', label: '待办业务', icon: 'el-icon-document', num: 0 },
        { Id: '6', label: '缓办业务', icon: 'el-icon-document-checked', num: 0 },
        { Id: '3', label: '收藏业务', icon: 'el-icon-star-off', num: 0 },
        { Id: '2', label: '办结业务', icon: 'el-icon-s-claim', num: 0 },
      ],
      ywlxList: [{ Id: '', label: '全部', icon: 'box', num: 0 }],
      itemTitle: '在办业务',
      itemstates: '1',
      itemyw: '0',
      inputValue: '', //搜索的内容
      queryId: 'lahz_cx',
      activeIndex: '1',
      userid: '',
      selectOption: "", //当前选项
      selectEnd: "已结案",
      tableHeaders2: [], //表头,
      para: {
        queryId: '',
        PageInfo: { pageNum: 1, pageSize: 10 },
        conditions: null,
      },
      holiday: [],      
    }
  },
  methods: {
    async getTaskCount() {
      let me = this;
      await this.$ajax
        .post(
          this.$appConfig.apiUrls.flowApi +
          '/api/WorkFlowTask/GetTaskCountByState'
        )
        .then(function (response) {
          if (response.data.IsSuccess) {
            let data = response.data.Data;
            for (let key1 in me.stateList) {
              for (let key2 in data) {
                if (me.stateList[key1].label === data[key2].StatsName) {
                  me.stateList[key1].num = data[key2].Count;
                }
              }
            }
          } else {
            ElMessage({
              type: 'error',
              message: response.data.ErrorMessage,
            })
          }
        })
        .catch(function (error) {
          console.log(error)
          ElMessage({
            type: 'error',
            message: error,
          })
        })
    },
    getstateItem(item) {
      this.activeIndex = item.Id
      this.itemstates = item.Id
      this.itemTitle = item.label
      this.whereconditions()
    },
    getywItem(item) {
      this.itemyw = item
      this.whereconditions()
    },
    //搜索
    handleQuery() {
      this.whereconditions()
    },
    whereconditions() {
      this.queryId = 'lahz_cx';
      let conditions = [
        {
          Key: 'xmmc,XMDM',
          Value: '%' + this.inputValue + '%',
          IsCondition: true,
          Operator: 'like',
          LogicalOperator: 'and',
        },
        {
          Key: 'wft.Status',
          Value: '' + this.itemstates,
          IsCondition: true,
          Operator: '=',
          LogicalOperator: 'and',
        },
      ]
      if (this.itemstates === '3') {
        this.queryId = 'lahz_cx_sc';
        conditions = [
          {
            Key: 'xmmc,XMDM',
            Value: '' + this.inputValue + '%',
            IsCondition: true,
            Operator: 'like',
            LogicalOperator: 'and',
          },
        ]
      } 
      if(this.itemstates === '0'){
        this.queryId='lahz_cx_db'
      }
      if (this.itemstates === '2') {
        if(this.selectEnd=='已结案'){
          this.queryId = 'lahz_cx_bj';
        }else{
          this.queryId = 'lahz_cx_yb1';
        }
        conditions = [
          {
            Key: 'xmmc,XMDM',
            Value: '%' + this.inputValue + '%',
            IsCondition: true,
            Operator: 'like',
            LogicalOperator: 'and',
          },
        ]
      }       
      if (this.itemyw !== '0') {
        conditions.push({
          Key: 'sblx',
          Value: '' + this.itemyw,
          IsCondition: true,
          Operator: '=',
          LogicalOperator: 'and',
        })
      }
      
      this.getLoadData(this.queryId, conditions)
    },
    //获取表头数据
    getLoadData(queryId, conditions) {
      this.para.queryId = queryId
      if (conditions === []) conditions = null
      this.para.conditions = conditions
      let me = this
      if (me.tableHeaders2.length > 0) {
        me.getDataList(me.para)
      } else {
        this.$ajax
          .get(this.$appConfig.apiUrls.tableApi + '/api/CommonTable/GetFileds?queryKey=' + queryId)
          .then(function (response) {
            if (response.data.isSuccess) {
              me.tableHeaders2 = response.data.data
              me.getDataList(me.para)
            } else {
              console.log(response.data.errorMessage)
            }
          })
      }
    },
    //获取表格数据
    async getDataList(para) {
      this.cName = para.queryId;
      this.para = para
      let me = this
      var res = await this.$ajax.post(
        this.$appConfig.apiUrls.tableApi + '/api/CommonTable/LoadData',
        JSON.stringify(para)
      )
      res = JSON.parse(res.data.data) || {rows:[],total:0}
      me.$refs.publicTable.getTData(me.para,res)
    },
    // 分页
    handlePageChange(val) {
      this.para.PageInfo.pageNum = val
      this.getDataList(this.para)
    },
    //重置
    handleReset() {
      this.inputValue = ''
      this.rendertable()
    },
    getworkflowlist() {
      let me = this
      return this.$ajax
        .get(
          me.$appConfig.apiUrls.flowApi +
            '/api/WorkFlow/GetWorkFlowList?userId=' +
            me.userid
        )
        .then(function (response) {
          if (response.data.IsSuccess) {
            me.ywlxList = [{ Id: '', label: '全部', icon: 'box', num: 4 }]
            me.selectOption = me.ywlxList[0].label;
            for (var key in response.data.Data) {
              me.ywlxList.push({
                Id: key,
                label: response.data.Data[key],
                icon: 'box',
                num: 4,
              })
            }
          } else {
            console.log(response.data.errorMessage)
          }
        })
    },
    rendertable() {
      this.selectOption = ''
      // let me = this
      // let conditions = [
      //   {
      //     Key: 'wft.Status',
      //     Value: me.activeIndex,
      //     IsCondition: true,
      //     Operator: '=',
      //     LogicalOperator: 'and',
      //   },
      // ]
      // if (me.activeIndex == '3') {
      //   conditions = []
      //}
      this.getywItem('')
      // me.getLoadData(me.queryId, conditions)
    },
    //超时相关
    isOvertime(jjrq, gt ,lt, et) {
        if(et){
            // console.log(et)
        }
      if (jjrq && (gt||lt) ) {
        const jjsj = new Date(jjrq)
        let jzsj = jjsj.setDate(jjsj.getDate() + (lt?lt:gt))
        return jzsj < new Date()
      } else {
        return false
      }
    },
    limitTime(row) {
      //判断是否需要存在工天
      let st = row.startWorkTime
      const wt = row.WorkTime
      const lt = row.LimitTime
      const et = row.taskendtime
      let day = 0
      let st_Date, jt_Date
      if (st) {
        st_Date = new Date(st)
      } else {
        return 0
      }
      //存在工天，判断是否为
      if (wt || lt) {
        //判断项目是否完结
        jt_Date = new Date(st)
        jt_Date.setDate(jt_Date.getDate() + (lt ? lt : wt) + 1)
        const holidays = this.hasHoliday(st_Date, jt_Date)
        jt_Date.setDate(jt_Date.getDate() + holidays)
        if (et) {
          const et_Date = new Date(et)
          if (et_Date < jt_Date) {
            return 2
          } else {
            return -1
          }
        } else {
          if (jt_Date < new Date()) {
            return -1
          } else {
            return (jt_Date - new Date()) / (1 * 24 * 60 * 60 * 1000)
          }
        }
      }
      return day
    },
    hasHoliday(startTime, endTime) {
      let holidays = 0
      if (startTime < endTime) {
        while (startTime <= endTime) {
          if (this.holiday.length > 0) {
            const h = this.holiday.filter(
              (hd) =>
                hd.date ==
                startTime.getFullYear() +
                  (startTime.getMonth() < 9 ? '0' : '') +
                  (startTime.getMonth() + 1) +
                  (startTime.getDate() < 10 ? '0' : '') +
                  (startTime.getDate())
            )
            if (h.length > 0) {
              holidays++
            }
          } else {
            if (startTime.getDay() === 6 || startTime.getDay() === 0) {
              holidays++
            }
          }
          startTime.setDate(startTime.getDate() + 1)
        }
      }
      return holidays
    },
    async getHoliday() {
      const me = this
      const today = new Date()
      await this.$ajax
        .get(
          'https://api.apihubs.cn/holiday/get?year=' +
            today.getFullYear() +
            '&workday=2&order_by=1&size=366'
        )
        .then(function (response) {
          if (
            response.data &&
            response.data.msg &&
            response.data.msg === 'ok'
          ) {
            me.holiday = response.data.data.list
            //console.log(me.holiday)
          }
        })
    },
    //打开当前列表单
    opentask(index,row) {
      if(this.itemTitle==="办结业务"){
        this.$router.push({ path: "/bjsx/info", query: { row: JSON.stringify(row) } });
      }else{
        let res = JSON.stringify(row)
        this.$router.push({ path: '/buslist/flowinfo', query: { row: res } })
      }
    },
    // 收藏相关
    setYwsc(index,row) {
      let me = this
      this.$ajax
        .get(
          me.$appConfig.apiUrls.ywxtApi +
          '/api/YW/SetFavorites?InstanceId=' +
          row.Id
        )
        .then(function (response) {
          if (response.data.IsSuccess) {
            ElMessage({
              type: 'success',
              message: response.data.Data ? '收藏成功' : '取消成功',
            })
            me.whereconditions()
            me.getTaskCount()
          } else {
            ElMessage({
              type: 'info',
              message: '收藏失败!',
            })
            console.log(response.data.errorMessage)
          }
        })
    },
    async getuser() {
      let user = await mgr.getUser()
      this.userid = user.profile.sub
      this.getworkflowlist()
      // this.handleReset()
    },
    refreshTable() {
      this.getTaskCount()
      const fid = this.$route.query.fid
      if (fid && this.ywlxList.some((ywlx) => ywlx.Id == fid)){
        this.selectOption = fid
        this.getywItem(fid)
      }else{
        this.rendertable()
      }
    }
  },
  components: {
    PublicTable,
  },
  mounted() {
    this.refreshTable()
    this.getuser()
    //this.getHoliday()
  },
  watch: {
    $route() {
      if (this.$route.name === "buslist") {
        this.refreshTable()
      }
    },
  },
}
</script>

<style scoped>
/* .el-row {
  margin-bottom: 20px;
} */
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
  /* border: red 1px solid; */
}

@media only screen and (min-width: 1200px) {
  .el-col-lg-4-8 {
    max-width: 20%;
  }
}

.grid-content {
  cursor: pointer;
  border: #ebe8e8 1px solid;
  margin-bottom: 10px;
  border-radius: 4px;
  min-height: 36px;
  overflow: hidden;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-left {
  border-right: #ebe8e8 1px solid;
  background-color: #F9FAFB;
  padding: 20px;
  display: inline-block;
}
.item-left i {
  font-size: 45px;
  color: #0191ea;
}

.item-right {
  padding-right: 10px;
  padding-left: 10px;
  display: inline-block;
  width: 50%;
  color: #0191ea;
}
.wrap-num {
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  width: 40px;
  color: #fff;
  box-sizing: border-box;
  text-align: center;
  border-radius: 10px;
  background-color: #0191ea;
}
/* 表头 */
.table-header {
  padding: 10px;
  overflow: hidden;
  /* border: 1px solid red; */
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table-header .el-select {
  width: 260px;
  margin: 0 10px 0 10px;
}
.wrap-iv {
  display: inline-block;
  margin-right: 5px;
}
.theader-left {
  min-width: 100px;
  margin-bottom: 10px;
  margin-left: 5px;
}
.wp-num {
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  width: 50px;
  color: #fff;
  box-sizing: border-box;
  text-align: center;
  border-radius: 10px;
  background-color: #ea0101;
  margin-left: 10px;
}
</style>